@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.studentScreenLoader {
    border: 5px solid #270f36;
    border-top: 5px solid white;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

.studentappview {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.studentcontainer {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: white;
}

.home-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #f3f1f2;
}

.home-top-text {
    margin: 44px 30px 0 30px;
}

.home-mid-text {
    margin: 18px 30px 0 30px;
}

.hello-text {
    font-size: 16px;
    text-align: left;
    color: #270f36;
    font-weight: 500;
}

.hello-mid-text {
    font-size: 14px;
    text-align: left;
    color: #270f36;
    font-weight: 500;
}

.hello-text-highlight {
    font-size: 16px;
    text-align: left;
    color: #e64b82;
    font-weight: 500;
}

.text-section-home {
    width: 100%;
    height: 100%;
    color: #270f36;
    background-color: #f3f1f2;
    font-size: 22px;
    line-height: 32px;
    font-weight: 500;
    flex: 1;
}

.home-bottom {
    display: flex;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("./assets/images/homework_states_01.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.home-bottom2 {
    display: flex;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radioSection {
    font-size: 18px;
    font-weight: 400;
    color: #6f6e6e;
}

.nudge-screen {
    display: flex;
    flex-direction: column;
    width: inherit;
    height: 100%;
    background-color: #faf1f0;
}

.radioSection input[type="radio"]:after {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: white;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 1px solid #707070;
    margin-right: 10px;
}

.radioSection input[type="radio"]:checked:after {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #270f36;
    content: "";
    display: inline-block;
    visibility: visible;
    margin-right: 10px;
}

.radiolikediv {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: white;
    border: 1px solid #270f36;
    content: "";
    display: inline-block;
    visibility: visible;
}

.radiolikedivselected {
    background-color: #270f36 !important;
}

.radioSection label {
    margin-left: 23px;
}

.textSelected {
    color: #864766;
}

.radioSelected {
    color: #864766;
}

.lottie-congrats {
    position: fixed;
}

.home-bg-img {
    width: 100%;
}

.join-now-button {
    background-color: #270f36;
    width: 227px;
    height: 45px;
    font-size: 18px;
    font-weight: 400;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.whoaretext {
    color: #864766;
    font-size: 21px;
    font-weight: 600;
    margin-top: 0px;
}
.whoaretext-secondary {
    color: #999;
    font-size: 14px;
    font-weight: 600;
    margin: 0px;
}

.whoaretext2 {
    color: #864766;
    font-size: 26px;
    line-height: 36px;
    font-weight: 600;
    margin-top: 70px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    width: 300px;
}

.whoamichooser {
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
}

.whoareoptionchooser {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 135px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    font-weight: 400;
    color: #6f6e6e;
}

.radioitemrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
}

.custominput {
    height: 40px;
    color: #270f36;
    display: flex;
    align-self: stretch;
    border: 1px solid #cacaca;
    outline: none;
    display: flex;
}

.custominput input {
    border: none;
}

.custominput input:focus {
    outline: none;
}

.otp-timer {
    border: 1px solid #cacaca;
    width: 40px;
    height: 40px;
    border-radius: 20px;
}
.phoneinputbox {
    height: 45px;
    color: #270f36;
    display: flex;
    align-self: stretch;
    border: 1px solid #cacaca;
    overflow-x: hidden;
}

.phoneinputrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    margin-left: 8px;
    font-weight: 400;
    font-size: 18px;
}

.input-item-row {
    height: 45px;
    width: 100%;
    color: #270f36 !important;
    background-color: white !important;
    border: 1px solid #cacaca !important;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: 0 8px;
    flex-direction: row;
    box-sizing: border-box;
    outline: none !important;
    overflow-y: hidden;
}

.input-item {
    height: 45px;
    width: 100%;
    color: #270f36 !important;
    background-color: white !important;
    border: 1px solid #cacaca !important;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: 0 8px;
    font-weight: 400;
    font-size: 18px;
    font-weight: 400;
    box-sizing: border-box;
    outline: none !important;
}

.input-item:hover,
.input-item:focus {
    outline: none !important;
    background-color: white !important;
}

.input-item:-internal-autofill-selected {
    background-color: white !important;
}

.input-item-not-outlined {
    height: 100%;
    width: 100%;
    color: #270f36 !important;
    background-color: white !important;
    overflow-x: hidden;
    font-weight: 400;
    font-size: 18px;
    font-weight: 400;
    box-sizing: border-box;
    outline: none !important;
    border: none;
}

.input-item-not-outlined:hover,
.input-item-not-outlined:focus {
    outline: none !important;
    background-color: white !important;
    border: none;
}

.input-item-not-outlined:-internal-autofill-selected {
    background-color: white !important;
    border: none;
}

.otpinputbox {
    height: 45px;
    color: #270f36;
    width: 190px;
    margin-top: 58px;
    align-self: center;
    border: 1px solid #cacaca;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 5px;
}

.otpinputbox input {
    width: 180px;
    text-align: center;
}
.resend-container {
    width: 230px;
    margin: 40px auto;
    display: flex;
    flex-direction: row-reverse;
}
.resendOTP {
    color: #864766;

    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
}

.resendCircle {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    border: 1px solid #cacaca;

    font-size: 20px;
    font-weight: 400;
    color: #270f36;

    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
}

.phoneinputrow input:focus {
    outline: none;
}

.otpinputbox input:focus {
    outline: none;
}

.chooser-text {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 700;
    color: #cacaca;
}

.chooser-text-selected {
    color: #864766;
}

.chooser {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.classroom-entry-screen {
    background-color: #f7f8fc;
    width: inherit;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.revision-series-info-screen {
    background-color: #f7f8fc;
    width: inherit;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.horizontal-scroll-bottom {
    overflow-x: auto;
    width: inherit;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 420px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 28px;
    padding-right: 28px;
}

.submit-bottom-popup {
    position: fixed;
    bottom: 0;
    width: inherit;
    background-color: white;
    border-radius: 15px 15px 0 0;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    align-items: center;
    box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.16);
}

.submit-bottom-sheet-new {
    position: fixed;
    height: 400px;
    bottom: 0;
    width: inherit;
    background-color: white;
    border-radius: 8px 8px 0 0;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    align-items: center;
    box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.16);
}

.bottom-sheet-gamification-holder {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    width: inherit;
    overflow-x: auto;
    overflow-y: hidden;
}

.bottom-sheet-gamification-holder::-webkit-scrollbar {
    display: none;
}

.empty-space {
    height: 320px;
    min-width: 8px;
    background-color: white;
}

.completion-text {
    color: #270f36;
    font-size: 20px;
    text-align: center;
    line-height: 32px;
    font-weight: 500;
    margin-top: 76px;
    margin-left: 36px;
    margin-right: 36px;
}

.completion-text-regular-large {
    color: #270f36;
    font-size: 26px;
    text-align: center;
    line-height: 32px;
    font-weight: 400;
    font-weight: 400;
    margin-left: 10px;
    margin-right: 10px;
}

.completion-sub-text {
    color: #270f36;
    font-size: 20px;
    text-align: center;
    line-height: 32px;
    font-weight: 500;
    margin-left: 36px;
    margin-right: 36px;
}

.completion-text-less-margin {
    color: #270f36;
    font-size: 20px;
    text-align: center;
    line-height: 32px;
    font-weight: 500;
    margin-top: 76px;
    margin-left: 12px;
    margin-right: 12px;
}

.mb-95px {
    margin-bottom: 95px;
}

.congrats-text {
    color: #864766;
    font-size: 26px;
    font-weight: 900;
    margin-left: 25px;
    margin-right: 25px;
    text-align: center;
    margin-top: 47px;
    font-weight: 700;
}

.correct-text {
    color: #864766;
    font-size: 26px;
    font-weight: 700;
    margin-left: 25px;
    margin-right: 25px;
    text-align: center;
    margin-top: 35px;
}

.correct-text-2 {
    color: #864766;
    font-size: 26px;
    font-weight: 700;
    margin-left: 25px;
    margin-right: 25px;
    text-align: center;
}

.completion-status-text {
    color: #555353;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    margin-left: 36px;
    margin-right: 36px;
    margin-bottom: 50px;
    text-align: center;
    margin-top: 26px;
}

.completion-status-text-less-margin {
    color: #555353;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 50px;
    text-align: center;
    margin-top: 26px;
}

.blur-bg {
    overflow-y: hidden !important;
}

.submit-bottom-popup-invi {
    position: relative;
    bottom: 0;
    width: inherit;
    display: none;
}
.chooser-img-container {
    background-color: #f6f6f6;
    border-radius: 50%;
    padding: 5px;
}
.chooser-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.whoareyousection {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 80%;
    height: 100vh;
    margin: 40px auto;
}

.whoareyousection2 {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px 30px;
}

.question-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.question-img {
    align-self: stretch;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    object-fit: contain;
    width: 100%;
}

.question-option {
    align-self: stretch;
    display: flex;
    background-color: #ffffff;
    color: #555353;
    border-radius: 8px;
    flex-direction: row;
    padding-right: 20px;
    padding-left: 10px;
    padding-top: 9px;
    padding-bottom: 30px;
    margin-bottom: 10px;
    border: 1px solid #555353;
    flex: 1 0 auto;
}

.question-option-selected {
    align-self: stretch;
    display: flex;
    background-color: #fff1ea;
    color: #555353;
    border-radius: 8px;
    flex-direction: row;
    padding-right: 20px;
    padding-left: 10px;
    padding-top: 9px;
    padding-bottom: 30px;
    margin-bottom: 10px;
    border: 1px solid #555353;
    flex: 1 0 auto;
    position: relative;
}

.question-option-incorrect {
    border-color: #b70000 !important;
    background-color: #ffc5bf !important;
}

.question-option-unattempted {
    border-color: #6f6e6e !important;
    background-color: transparent;
}

.question-option-correct {
    border-color: #1e8e3e !important;
    background-color: #e6f4ea !important;
}

.option-identifier-box {
    background-color: #ffffff;
    height: 26px;
    width: 26px;
    max-width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #555353;
    font-size: 14px;
    font-weight: 700;
    border-radius: 4px;
    border: 1px solid #555353;
    flex: 1 0 auto;
    box-sizing: border-box;
}

.option-identifier-box-unattempted {
    border: 1px solid #555353;
    color: #555353;
    background-color: #ffffff;
}

.option-identifier-box-selected {
    background-color: #270f36;
    height: 26px;
    width: 26px;
    max-width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 14px;
    font-weight: 700;
    border-radius: 4px;
    border: none;
    flex: 1 0 auto;
    box-sizing: border-box;
}

.option-text {
    margin-left: 10px;
    font-size: 18px;
    color: #555353;
    font-weight: 600;
    width: -webkit-calc(100% - 36px);
    width: -moz-calc(100% - 36px);
    width: calc(100% - 36px);
}

.option-text-unattempted {
    color: #555353 !important;
}

.question-card {
    background-color: #ffffff;
    margin-top: 30px;
    margin-left: 14px;
    margin-right: 14px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    flex: 1 0 auto;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.16);
    overflow-wrap: break-word;
    white-space: unset;
}

.question-card .katex {
    white-space: unset !important;
    font-size: 1em !important;
}

.question-bb {
    background-color: #fff1ea;
    margin-top: 20px;
    margin-left: 14px;
    margin-right: 14px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    color: #555353;
    font-size: 24px;
    font-weight: 400;
    text-align: left;
    border-radius: 8px;
    flex: 1 0 auto;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.question-container {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    color: #555353;
}

.question-bb-unattempt {
    color: #f5a014 !important;
}

.question-bb-correct {
    color: #1e8e3e !important;
}

.question-bb-incorrect {
    color: #b70000 !important;
}

.question-number-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.qIcon-container {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.qIcon {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.oIcon-container {
    position: absolute;
    right: 4px;
    top: 2px;
    width: 18px;
    height: 18px;
}

.oIcon {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.tagsContainer {
    padding-top: 2px;
    padding-right: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.qTag {
    background-color: rgba(240, 159, 156, 0.7);
    padding: 0px 10px;
    color: #270f36;
    font-size: 12px;
    font-weight: 500;
    font-weight: 500;
    margin: 10px 4px 0 4px;
    border-radius: 12px;
    height: 24px;
    line-height: 24px;
}

.subjQTag {
    background-color: rgba(240, 159, 156, 0.7);
    padding: 0px 10px;
    color: #270f36;
    font-size: 12px;
    font-weight: 500;
    font-weight: 500;
    margin: 10px 4px 0 4px;
    border-radius: 12px;
    height: 24px;
    line-height: 24px;
}

.mathRender {
    width: 100%;
    line-break: auto;
    display: block;
    background: transparent;
}

.htmlParentDiv {
    background-color: white;
    padding: 0 12px;
    max-width: inherit;
    overflow-x: auto;
    margin-bottom: 22px;
}

.homework-view-bottom {
    width: inherit;
    display: flex;
    flex-direction: column;
    flex: 1 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: calc(100% - 80px);
}

.homework-review-bottom {
    width: inherit;
    display: flex;
    flex-direction: column;
    flex: 1 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: calc(100% - 80px);
}

.badge-yellow {
    display: inline;
    padding: 4px 7px;
    background-color: rgba(245, 160, 20, 0.66);
    color: #270f36;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
    line-height: 15px;
    margin-right: auto;
}

.badge-holder {
    display: flex;
    flex-direction: row;
    padding-left: 18px;
    margin-bottom: 16px;
}

.correct-answer-span {
    color: #6f6e6e;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    font-weight: 700;
    margin-bottom: 4px;
}

.feedbackImage {
    width: 100%;
    height: 100%;
}

.feedbackImageMaps {
    position: absolute;
    background-color: #01c720;
    width: 36px;
    height: 36px;
    color: white;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    border-radius: 100%;
    padding: 5px 0;
    box-sizing: border-box;
}

.not-corrected-watermark {
    position: absolute;
    background-color: #6c6371;
    padding: 4px 10px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    top: 12px;
    right: 12px;
}

.watermark-text {
    font-size: 10px;
    color: white;
    font-weight: 400;
    text-align: center;
}

.homework-view-bottom::-webkit-scrollbar {
    width: 4px;
}

.homework-view-bottom::-webkit-scrollbar-thumb {
    background-color: rgba(134, 71, 102, 0.55);
    border-radius: 2px;
}

.text-top {
    margin-left: 32px;
    padding-top: 102px;
}

.text-inter {
    margin-left: 32px;
    padding-top: 18px;
    margin-right: 36px;
}

.text-highlight {
    color: #e64b82;
}

.identity-screen-next {
    height: 76px;
    width: inherit;
    position: fixed;
    bottom: 0;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-screen-cta-holder {
    height: 70px;
    width: inherit;
    position: fixed;
    bottom: 0;
    background-color: #f3f1f2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.home-screen-cta {
    height: 45px;
    width: 248px;
    background-color: #270f36;
    color: white;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    outline: none;
    border: none;
    border-radius: 4px;
}

.home-media-holder {
    margin-top: 10px;
}

.identity-screen-next-inactive {
    height: 76px;
    width: inherit;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.next-button-inactive {
    height: 48px;
    background-color: #b6b6b6;
    color: #f5f5f5;
    border: none !important;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
    margin-right: 12px;
    margin-left: 12px;
    margin-top: auto;
    align-self: stretch;
    font-size: 18px;
    font-weight: 400;
    font-weight: 400;
}

.next-button-loading {
    height: 48px;
    background-color: #b6b6b6;
    color: #f5f5f5;
    border: none !important;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
    margin-right: 12px;
    margin-left: 12px;
    margin-top: auto;
    align-self: stretch;
    font-size: 18px;
    font-weight: 400;
    font-weight: 400;
}

.next-button-active {
    height: 48px;
    background-color: #270f36;
    color: #f5f5f5;
    border: none !important;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
    margin-right: 12px;
    margin-left: 12px;
    margin-top: auto;
    align-self: stretch;
    font-size: 18px;
    font-weight: 400;
    font-weight: 400;
}

.identityScreen {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.submit-button-active {
    height: 48px;
    background-color: #270f36;
    color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    margin-right: 12px;
    margin-left: 12px;
    margin-top: 20px;
    align-self: stretch;
    padding: 16px;
    flex: 1 0 auto;
    max-height: 48px;
    box-sizing: border-box;
}

.bottom-sheet-button {
    height: 48px;
    background-color: #270f36;
    color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
    margin-right: 12px;
    margin-left: 12px;
    margin-top: 20px;
    align-self: stretch;
    z-index: 10;
    padding: 16px;
    flex: 1 0 auto;
    max-height: 48px;
    box-sizing: border-box;
}

.bottom-sheet-button-light {
    height: 48px;
    background-color: #ffffff;
    border: 1px solid #270f36;
    color: #270f36;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
    margin-right: 12px;
    margin-left: 12px;
    margin-top: 20px;
    align-self: stretch;
    z-index: 10;
    padding: 16px;
    flex: 1 0 auto;
    max-height: 48px;
    box-sizing: border-box;
}

.filling-button-bar {
    height: 48px;
    background-color: #f09f9c;
    color: #270f36;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
    margin-right: 12px;
    margin-left: 12px;
    margin-top: 20px;
    align-self: stretch;
    z-index: 10;
    padding: 16px;
    flex: 1 0 auto;
    max-height: 48px;
    box-sizing: border-box;
}

.bottom-sheet-small-buttons {
    justify-content: center;
    height: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    box-sizing: border-box;
}

.button-eggplant-bg {
    background-color: #270f36;
    color: white;
}

.button-eggplant-bd {
    background-color: white;
    color: #270f36;
    border: 1px solid #270f36;
}

.bottom-sheet-button-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
    margin-right: 12px;
    margin-left: 12px;
    margin-top: 20px;
    align-self: stretch;
    z-index: 10;
    font-size: 18px;
}

.big-rounded-div {
    width: 72px;
    height: 72px;
    margin: 50px auto 25px auto;
}

.big-rounded-div-bordered {
    width: 72px;
    height: 72px;
    margin: 50px auto 25px auto;
    border: 1px solid #707070;
    border-radius: 100%;
}

.big-rounded-div-centered {
    width: 112px;
    height: 112px;
    margin: 100px 150px 10px 150px;
    border-radius: 100%;
}

.bottom-card-rounded-div {
    width: 112px;
    height: 112px;
    margin: 64px auto 23px auto;
    border-radius: 100%;
    background-color: white;
}

.modal-lotties-margin-less {
    width: 72px;
    height: 72px;
    border-radius: 100%;
    border: 1px #707070 solid;
}

.text-completion-medium {
    color: #270f36;
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
    text-align: center;
    font-weight: 500;
}

.text-completion-note {
    color: #555353;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 40px;
}

.text-completion-note-margin-less {
    color: #555353;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    font-weight: 500;
}

.contact-teacher-button,
.contact-teacher-button:hover {
    height: 48px;
    background-color: #270f36;
    color: white !important;
    border-radius: 4px;
    display: flex;
    margin-bottom: 40px;
    margin-right: 12px;
    margin-left: 12px;
    margin-top: 20px;
    align-self: stretch;
    /* padding: 16px; */
    flex: 1 0 auto;
    max-height: 48px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    box-sizing: border-box;
}

.whatsapp-icon-container {
    width: 20px;
    height: 20px;
    margin-right: 7px;
    /* margin-top: -9px; */
}

.whatsapp-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.profile-box-next {
    height: 76px;
    width: inherit;
    position: fixed;
    bottom: 0;
    background-color: #f3f1f2;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.profile-img {
    border-radius: 50%;
}

.profile-box {
    width: 100%;
    height: 100px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    margin: 15px auto;
    border-radius: 4px;
    justify-content: flex-start;
    padding-left: 15px;
    overflow-x: scroll;
    box-sizing: border-box;
}

.profile-box-text {
    font-weight: 700;
    font-size: 18px;
    color: #cacaca;
    margin-left: 13px;
}

.profile-box-text-high {
    font-weight: 700;
    font-size: 18px;
    color: #864766;
    margin-left: 13px;
}

.student-view {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.homework-view {
    display: flex;
    flex-direction: column;
    width: inherit;
    height: 100%;
    top: 0;
    position: fixed;
}

.homework-review {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
}

.profiles-view {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    min-height: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 30px;
}

.already-text {
    font-size: 18px;
    font-weight: 600;
    margin-top: 16px;
    text-align: center;
}

.homework-box-row-centered {
    display: flex;
    flex-direction: row;
    padding-left: 16px;
    padding-right: 16px;
    align-self: stretch;
    align-items: flex-start;
}

.homework-row {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    align-items: center;
}

.homework-row-alt {
    display: flex;
    flex-direction: row;
    margin-left: auto;
}

.homework-duetime-row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    height: 20px;
    justify-self: flex-end;
    margin-left: auto;
}

.quiz-duration-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;
    justify-self: flex-end;
    margin-left: auto;
}

.extendedBadge {
    border-radius: 4px;
    padding: 0 6px;
    background-color: #fdf7f4;
    color: #270f36;
    font-size: 10px;
    font-weight: 500;
    font-weight: 500;
}

.auto-submitted-badge {
    background-color: white;
    font-size: 8px;
    padding: 3px 8px 2px 8px;
    align-items: center;
    border-radius: 4px;
    font-weight: 600;
    font-weight: 600;
    height: 16px;
}

.quiz-badge {
    background-color: white;
    font-size: 8px;
    padding: 1px 3px 1px 3px;
    align-items: center;
    border-radius: 4px;
    font-weight: 400;
    font-weight: 400;
}

.quiz-badge-orange {
    color: #f5a014 !important;
}

.quiz-badge-green {
    color: #47a254 !important;
}

.quiz-badge-blue {
    color: #4570a0 !important;
}

.quiz-badge-dark-blue {
    color: #1e0abe !important;
}

.quiz-badge-black {
    color: #555353 !important;
}

.quiz-badge-red {
    color: #dd0000 !important;
}

.quiz-badge-indigo {
    color: #1e0abe !important;
}

.scoreLabel {
    color: #555353;
    font-weight: 500;
    font-size: 18px;
    font-weight: 500;
}

.scoreLine {
    color: #270f36;
    font-weight: 600;
    font-size: 20px;
    font-weight: 600;
}

.homework-row-end {
    margin-left: auto;
}

.homework-bold {
    font-weight: 900;
    font-size: 20px;
    font-weight: 700;
    max-height: 76px;
}

.homework-duetime {
    font-weight: 700;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
}

.quiz-duration {
    font-weight: 600;
    font-size: 8px;
    font-weight: 600;
}

.homework-small-bold {
    font-weight: 900;
    font-size: 12px;
    font-weight: 700;
}

.homework-light {
    font-size: 12px;
    font-weight: 500;
}

.homework-column {
    display: flex;
    flex-direction: column;
}

.homework-sep {
    align-self: stretch;
    height: 1px;
    background-color: white;
    margin-left: 16px;
    margin-right: 16px;
}

.chapter-text {
    margin-left: 16px;
    margin-right: 16px;
    font-size: 18px;
    font-weight: 500;
}

.action-button {
    background-color: white;
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
    margin-bottom: 15px;
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    font-weight: 500;
}

.action-button-orange {
    color: #f5a014 !important;
}

.action-button-green {
    color: #47a254 !important;
}

.action-button-blue {
    color: #4570a0 !important;
}

.action-button-dark-blue {
    color: #1e0abe !important;
}

.action-button-black {
    color: #555353 !important;
}

.action-button-red {
    color: #dd0000 !important;
}

.action-button-indigo {
    color: #4d1e8d !important;
}

.student-text-bold {
    font-size: 16px;
    color: #270f36;
    font-weight: 700;
    font-weight: 900;
}

.student-text-sub {
    font-size: 12px;
    color: #864766;
    font-weight: 400;
}

.studentappviewloader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.addImageButton {
    min-width: 80px;
    width: 80px;
    height: 90px;
    background-color: #f09f9c;
    border-radius: 12px;
    margin-bottom: 0;
    border: 1px solid #864766;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.29);
}

.uploadHeaderContainer {
    display: flex;
    flex-direction: column;
    /* width: inherit; */
    padding: 12px 16px 10px 16px;
    background-color: #fff1ea;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    z-index: 10;
}

.uploadHeaderTopRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.uploadHeaderSecondRow {
    color: #864766;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin: 15px 0 5px 0;
}

.uploadFooterContainer {
    width: 100%;
    bottom: 0;
}

.uploadFooterTextRow {
    text-align: center;
    font-size: 12px;
    line-height: 13px;
    color: #270f36;
    font-weight: 500;
    font-weight: 500;
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: #f2f2f2;
}

.uploadFooterItemsRow {
    background-color: #fff1ea;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.16);
    padding: 10px;
}

.uploadButton {
    background-color: #fff1ea;
    color: #270f36;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
}

.imagesPreviewContainer {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    max-width: 75%;
    margin-right: 10px;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.imagesPreviewContainer::-webkit-scrollbar {
    display: none;
}

.imageSmallPreviewContainer {
    position: relative;
    margin-right: 20px;
    margin-top: 15px;
}

.imageSmallPreview {
    width: 80px;
    height: 90px;
    border-radius: 12px;
    border: 0.5px solid #864766;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.29);
}

.imageSmallPreviewSelected {
    border: 2px solid #270f36 !important;
}

.removeImageIcon {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 24px;
    height: 24px;
}

.imageNumberText {
    position: absolute;
    top: 6px;
    left: 6px;
    font-size: 8px;
    line-height: 10px;
    text-align: left;
    color: #270f36;
    font-weight: 400;
}

.uploadView {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    width: inherit;
    height: 100%;
}

.bigImageHolder {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    flex: 1 0;
    overflow-y: auto;
    padding: 15px 25px 0 25px;
}

.bigImage {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.29);
}

.addFilesHere {
    min-width: 80%;
    padding: 30px 20px;
    border-radius: 8px;
    border: 1px solid #555353;
    background-color: #fff1ea;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.addImageIcon {
    width: 30px;
    height: 30px;
    margin-right: 12px;
    margin-top: -5px;
}

.img-contain {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.whiteClockIcon {
    width: 12px;
    height: 100%;
    object-fit: contain;
}

.sandClockIcon {
    width: 10px;
    height: 100%;
    object-fit: contain;
}

.text-normal-sm {
    font-size: 18px;
    color: #555353;
    line-height: 21px;
    font-weight: 400;
}

.answeredImageContainer {
    position: relative;
    margin: 10px auto 25px auto;
    width: 100%;
    height: auto;
}

.answeredImage {
    width: 100%;
    height: 100%;
}

.pencilIcon {
    position: absolute;
    width: 36px;
    height: 36px;
    top: 8.5px;
    right: 8.5px;
}

.moreImagesClick {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0 0 0;
}

.moreImagesText {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    /* line-height: 5px; */
    color: #270f36;
    font-weight: 500;
}

.subjQuestionTags {
    width: -webkit-calc(100% - 52px);
    width: -moz-calc(100% - 52px);
    width: calc(100% - 52px);
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
}

.crossXButton {
    width: 24px;
    height: 24px;
}

.subjQuestionContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    color: #555353;
    font-size: 24px;
    font-weight: 400;
    text-align: left;
}

.subjQuestionView {
    background-color: white;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    min-height: 100%;
    overflow-wrap: break-word;
    white-space: unset;
}

.subjQuestionView .katex {
    white-space: unset !important;
    font-size: 1em !important;
}

.subjQuestionTopRow {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 18px 14px 18px 14px;
    min-height: 36px;
}

.solutionContainer {
    display: flex;
    flex-direction: column;
    padding: 0 8px;
}

.solutionDivider {
    border: 0;
    border-top: 1px solid #6f6e6e;
    margin: 0 16px;
}

.solutionToggleHolder {
    height: 56px;
    padding: 0 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.solutionToggleText {
    font-size: 14px;
    color: #555353;
    font-weight: 600;
    font-weight: 600;
}

.commentDialog {
    background: none !important;
    overflow-y: hidden !important;
}

.bottomSheetDialog {
    overflow-x: hidden;
    overflow-y: hidden;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    width: inherit;
    height: 100%;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    transition-delay: 0s;
    background: rgba(0, 0, 0, 0.5);
}

.modalDialog {
    overflow-x: auto;
    overflow-y: auto;
    position: fixed;
    top: 0;
    z-index: 1000;
    width: inherit;
    height: 100%;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    transition-delay: 0s;
    background: rgba(0, 0, 0, 0.5);
}

.modalDocument {
    display: flex;
    align-items: center;
    min-height: calc(100% - 4rem);
    position: relative;
    width: auto;
    pointer-events: none;
    margin: 0.5rem;
}

.gameModalDocument {
    display: flex;
    align-items: center;
    min-height: calc(100% - 4rem);
    position: relative;
    width: inherit;
    pointer-events: none;
    flex: 1 0 auto;
}

.commentDocument {
    margin: 0.5rem 3rem !important;
}

.centeredDocument {
    min-height: calc(100% - 1rem) !important;
}

.modalContent {
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    min-height: 40%;
    padding: 10px;
    pointer-events: auto;
    background-color: white;
    background-clip: padding-box;
    border-radius: 10px;
    outline: 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
}

.gameModalContent {
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    width: inherit;
    height: auto;
    min-height: 40%;
    pointer-events: auto;
    background-clip: padding-box;
    border-radius: 10px;
    outline: 0;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    flex: 1 0 auto;
}

.deleteModalContent {
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    width: auto;
    height: auto;
    min-height: 40%;
    padding: 10px;
    pointer-events: auto;
    background-color: white;
    background-clip: padding-box;
    border-radius: 10px;
    outline: 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
}

.commentContent {
    border-radius: 4px !important;
    border: 0.5px solid #707070;
}

.commentPopupHeaderRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px;
}

.commentHeaderRowCombiner {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.roundedSpan {
    width: 36px;
    height: 36px;
    color: white;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    border-radius: 100%;
    background-color: #ba4bd0;
    padding: 5px 0;
    box-sizing: border-box;
}

.modalBodySpace {
    width: 100%;
    margin: 40px 0;
    display: flex;
    flex-direction: column;
}

.gameModalBodySpace::-webkit-scrollbar {
    display: none;
}

.gameModalBodySpace {
    width: auto;
    height: 320px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    align-items: center;
    padding-left: 20px;
}

.badgeCard {
    background-color: #fbbf00;
    min-width: 260px;
    max-width: 260px;
    height: 320px;
    border-radius: 20px;
    box-shadow: 2px 3px 8px 0 rgba(0, 0, 0, 0.29);
    margin: 0 auto;
    position: relative;
}

.pointsCard {
    background-color: #e64b82;
    min-width: 260px;
    max-width: 260px;
    height: 320px;
    border-radius: 20px;
    box-shadow: 2px 3px 8px 0 rgba(0, 0, 0, 0.29);
    margin: 0 auto;
    position: relative;
}

.streakCard {
    background-color: #ff421d;
    min-width: 260px;
    max-width: 260px;
    height: 320px;
    border-radius: 20px;
    box-shadow: 2px 3px 8px 0 rgba(0, 0, 0, 0.29);
    margin: 0 auto;
    position: relative;
}

.reward-confetti-holder {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
}

.rewardContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (min-width: 321px) {
    .badgeCard {
        min-width: 300px;
        max-width: 300px;
    }

    .pointsCard {
        min-width: 300px;
        max-width: 300px;
    }

    .streakCard {
        min-width: 300px;
        max-width: 300px;
    }
}

@media only screen and (min-width: 376px) {
    .badgeCard {
        min-width: 320px;
        max-width: 320px;
    }

    .pointsCard {
        min-width: 320px;
        max-width: 320px;
    }

    .streakCard {
        min-width: 320px;
        max-width: 320px;
    }
}

.game-left {
    margin-left: 20px !important;
}

.game-right {
    margin-right: 20px !important;
}

.gameDisplayCircle {
    background-color: #fff;
    width: 112px;
    height: 112px;
    margin-top: 64px;
    border-radius: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gameDisplayContainer {
    margin-top: 87px;
    display: flex;
    flex-direction: row;
}

.gameDisplaySmall {
    background-color: #fff;
    width: 64px;
    height: 64px;

    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gameText {
    color: #fff;
    font-weight: 900;
    font-size: 26px;
    margin-top: 23px;
    text-align: center;
    font-weight: 700;
}

.gameTextBlue {
    color: #1e0abe;
    font-weight: 900;
    font-size: 26px;
    margin-top: 23px;
    text-align: center;
    font-weight: 700;
}

.gameTextSub {
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    margin-top: 18px;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    font-weight: 400;
}

.gameTextSubBlueButton {
    height: 48px;
    color: #1e0abe;
    border-radius: 8px;
    border: 1px solid #1e0abe;
    font-size: 18px;
    font-weight: 400;
    font-weight: 400;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 22px;
    margin-left: 40px;
    margin-right: 40px;
}

.deletedModalBodySpace {
    width: 100%;
    margin: 30px 0 20px 0;
    display: flex;
    flex-direction: column;
}

.quizStartedModalBodySpace {
    width: 100%;
    margin: 26px 0 26px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalBodyText {
    color: #555353;
    font-size: 16px;
    text-align: center;
    line-height: 32px;
    font-weight: 500;
    font-weight: 400;
    margin: 40px auto;
    width: 60%;
}

.modalBodySubText {
    color: #555353;
    font-size: 16px;
    text-align: center;
    line-height: 32px;
    font-weight: 500;
    font-weight: 400;
    margin: 10px auto;
    width: 80%;
}

.modalBodySubText2 {
    color: #555353;
    font-size: 16px;
    text-align: center;
    line-height: 32px;
    font-weight: 500;
    font-weight: 400;
    margin: 0 auto;
    width: 100%;
}

.deletedHWModalBodyText {
    color: #555353;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    font-weight: 500;
    margin: 0 auto;
    width: 60%;
}

.deletedHWModalBodySubText {
    color: #555353;
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    font-weight: 400;
    margin: 20px auto 0 auto;
    width: 80%;
}

.quizStartedModalBodyText {
    color: #555353;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    font-weight: 600;
    width: 80%;
}

.quizStartedModalBodySubText {
    color: #555353;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    font-weight: 500;
    margin: 16px auto 0 auto;
    width: 80%;
}

.quizScoreModalBodyText {
    color: #270f36;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    font-weight: 700;
    width: 80%;
}

.quizScoreModalBodySubText {
    color: #555353;
    font-size: 13px;
    text-align: center;
    font-weight: 500;
    font-weight: 500;
    width: 80%;
}

.commentBodyText {
    color: #555353;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    font-weight: 400;
}

.commentHeaderText {
    color: #6f6e6e;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    width: 125px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.modalButtonHolder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 10px 10px 10px;
}

.modalButtonsHolder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 20px 10px 10px 10px;
}

.modalButton {
    color: #270f36;
    background-color: white;
    font-size: 18px;
    font-weight: 400;
    font-weight: 400;
    line-height: 24px;
    border: none;
    outline: none;
}

.modalButton:focus {
    outline: none;
}

.addExtraHeight {
    height: 150px;
    display: block;
}

.deleteImagePopup {
    width: 245px;
    height: 235px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: auto;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    pointer-events: auto;
    box-sizing: border-box;
}

.deleteImagePopupText {
    margin-top: 48px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 60px;
    color: #555353;
    font-size: 16px;
    text-align: center;
    line-height: 32px;
    font-weight: 500;
    font-weight: 400;
    width: 212px;
    height: 96px;
    padding: 0 5px;
}

.deleteImagePopupButtons {
    display: flex;
    flex-direction: row;
    margin-right: 31px;
    margin-left: 31px;
    margin-bottom: 16px;
    justify-content: space-between;
}

.quizStartedPopupButtons {
    display: flex;
    flex-direction: row;
    margin-right: 8px;
    margin-left: 8px;
    margin-bottom: 4px;
    justify-content: space-between;
}

.deleteImagePopupButton {
    color: #270f36;
    background-color: white;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    border: none;
    outline: none;
}

.commentPopup {
    width: 260px;
    height: auto;
    border-radius: 4px;
    background-color: white;
    border: 0.5px solid #707070;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    flex-direction: column;
    display: flex;
    margin: auto;
    pointer-events: auto;
    box-sizing: border-box;
}

.commentPopupText {
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 5px;
    margin-bottom: 40px;
}

.uploadingModal {
    width: 300px;
    height: 210px;
    border-radius: 8px;
    background-color: white;
    flex-direction: column;
    display: flex;
    margin: auto;
    pointer-events: auto;
    box-sizing: border-box;
}

.uploadingModalTopText {
    margin-top: 30px;
    text-align: center;
}

.uploadingModalSubText {
    font-size: 12px;
    color: #555353;
    font-weight: 400;
    text-align: center;
    margin-top: 4px;
}

.uploadingModalDangerText {
    font-size: 12px;
    color: #b70000;
    font-weight: 400;
    margin-top: 4px;
    margin-left: 30px;
}

.uploadingModalPercentage {
    font-size: 10px;
    color: #555353;
    font-weight: 400;
    margin-top: 36px;
    margin-bottom: 4px;
    margin-left: 250px;
}

.flex-row-justify-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.retryText {
    font-size: 18px;
    color: #270f36;
    font-weight: 400;
    line-height: 26px;
}

.progress-bar {
    width: 244px;
    height: 4px;
    margin: 0 auto;
    background-color: #e7e7e7;
}

.progress-filler {
    background-color: #270f36;
    height: 100%;
    border-radius: inherit;
    transition: width 0.1s ease-in;
}

@media only screen and (min-height: 550px) {
    .addExtraHeight {
        height: 0;
        display: none;
    }
}

@media only screen and (min-width: 600px) {
    .studentappview {
        width: 100%;
        height: 100%;
        background-color: white;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .studentcontainer {
        width: 100vw;
        height: 100vh;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (min-width: 768px) {
    .studentappview {
        width: 768px;
        height: 100%;
        background-color: white;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    .studentcontainer {
        width: 100vw;
        height: 100vh;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

/* tailwind type css */

.p-8px {
    padding: 8px;
}

.p-20px {
    padding: 20px;
}

.h-15px {
    height: 15px;
}

.ml-2px {
    margin-left: 2px;
}

.mr-2px {
    margin-right: 2px;
}

.mt-4px {
    margin-top: 4px;
}

.ml-4px {
    margin-left: 4px;
}

.mr-4px {
    margin-right: 4px;
}

.mt-6px {
    margin-top: 6px;
}

.ml-6px {
    margin-left: 6px;
}

.mr-6px {
    margin-right: 6px;
}

.ml-8px {
    margin-left: 8px;
}

.mr-8px {
    margin-right: 8px;
}

.ml-10px {
    margin-left: 10px;
}

.mr-10px {
    margin-right: 10px;
}

.mt-16px {
    margin-top: 16px;
}

.ml-16px {
    margin-left: 16px;
}

.mr-16px {
    margin-right: 16px;
}

.mb-16px {
    margin-bottom: 16px;
}

.mt-20px {
    margin-top: 20px;
}

.ml-20px {
    margin-left: 20px;
}

.mr-20px {
    margin-right: 20px;
}

.mb-20px {
    margin-bottom: 20px;
}

.mb-25px {
    margin-bottom: 25px;
}

.mt-30px {
    margin-top: 30px;
}

.mt-36px {
    margin-top: 36px;
}

.text-center {
    text-align: center;
}

.text-12px {
    font-size: 12px;
}

.text-10px {
    font-size: 10px;
}

.c-light-nanocide {
    color: #555353;
}

.c-danger {
    color: #b70000 !important;
}

.bg-danger {
    background-color: #b70000 !important;
}

.font-montserrat-medium {
    font-weight: 500;
}

.font-montserrat-regular {
    font-weight: 400;
}

.checkboxclass {
    width: 25px;
    height: 25px;
    border-radius: 1px;
    background-color: white;
    border: 1px solid #270f36;
    content: "";
    display: inline-block;
    visibility: visible;
}
.top-box {
    /* width: 100%; */
    padding: 0px 20px;
    margin: auto;
    height: 55px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-items: center;
}
.top-box-container {
    width: 100%;
    background-color: #fce8e6;
    box-shadow: 0px 1px 8px #b6b6b6;
}
.top-text-container {
    flex: 90%;
}
.dots-container {
    flex: 10%;
    height: 20px;
    display: flex;
}
.filled-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #864766;
    background-color: #864766;
    margin-right: 10px;
}
.empty-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #864766;
    background-color: white;
}
.bold {
    font-weight: 700;
}
.back-button {
    width: 47px;
    height: 30px;
    margin-right: 20px;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.otp-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.otp-digit-box {
    width: 40px;
    height: 40px;
    margin: 0px 10px;
    border: 1px solid #cecece;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

.invisible-otp-input {
    height: 40px;
    width: 220px;
    margin: auto;
    margin-top: -45px;
    margin-left: 10px;
}

.invisible-otp-input input {
    width: 220px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
}

.checkbox-label {
    color: #999;
    font-size: 17px;
    font-weight: 600;
}

.checkboxitemrow {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    align-items: center;
}

.custom-checkbox {
    width: 25px;
    height: 25px;
    color: white;
    border-radius: 2px;
    border: 1px solid #cacaca;
    display: flex;
    justify-content: center;
    align-items: center;
}
.checked-custom-checkbox {
    background-color: #4287f5;
}
.selected-option {
    border: 1px solid #cacaca;
    padding: 10px 10px;
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}
.dropdown-container {
    position: relative;
}

.dropdown-options-container {
    border: 2px solid #e7e7e7;
    position: absolute;
}
.dropdown-icon-container {
    float: right;
    width: 20px;
    height: 20px;
}
.dropdown-option {
    margin: 10px;
}
.field-error {
    display: inline;
    color: #b70000;
    font-size: 12px;
    font-weight: 500;
    margin-left: 7px;
}
.secure-hidden {
    opacity: 0;
}
.secure-box {
    position: fixed;
    z-index: 2;
    bottom: 100px;
    left: 50%;
    transform: translate(-50%, 0%);
    text-align: center;
    color: #9e9e9e;
    font-size: 14px;
}
.secure-icon {
    width: 20px;
    height: 20px;
    color: rgb(15, 175, 15);
    margin-right: 7px;
}
.add-container {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 30px;
    background-color: #e7e7e7;
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-active {
    color: #270f36;
}

.add-inactive {
    color: #a3a3a3;
}

.country-code-inactive {
    color: #cacaca;
    font-size: 18px;
    font-weight: 400;
    font-weight: 400;
    margin-right: 8px;
}

.country-code-active {
    color: #270f36;
    font-size: 18px;
    font-weight: 400;
    font-weight: 400;
    margin-right: 8px;
}

.otp-error {
    color: #b70000;
    font-size: 12px;
    font-weight: 500;
    width: 220px;
    margin: auto;
    margin-bottom: 10px;
}
.otp-error-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.text-cursor {
    width: 2px;
    height: 20px;
    background-color: rgb(70, 0, 135);
}
.otp-modal-body {
    text-align: center;
    margin: 26px 24px;
}
.otp-header {
    font-size: 16px;
    color: #555353;
}
.otp-text {
    font-size: 12px;
    color: #555353;
    margin-bottom: 10px;
}

.otp-btn {
    font-family: inherit;
    display: block;
    border: 1px solid #000;
    border-radius: 4px;
    background-color: white;
    color: #270f36;
    width: 100%;
    margin: 10px 0;
    padding: 12px;
    font-weight: 700;
    cursor: pointer;
}

.otp-btn-disabled {
    font-family: inherit;
    display: block;
    border-radius: 4px;
    background-color: white;
    width: 100%;
    margin: 10px 0;
    padding: 12px;
    font-weight: 700;
    color: grey;
    border: 1px solid grey;
    cursor: not-allowed;
}

.border-box-imp {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
