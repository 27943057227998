.sentry-error-main {
    background-color: #f7f8fc;
    display: flex;
    flex-direction: column;
    width: inherit;
    height: inherit;
    align-items: center;
}

.sentry-error-lottie-holder {
    width: 120px;
    height: 120px;
    margin-top: 100px;
    margin-bottom: 64px;
    border-radius: 100%;
}

.sentry-error-main-text {
    font-size: 18px;
    font-weight: 500;
    font-weight: 500;
    height: 32px;
    color: #864766;
    width: 260px;
    text-align: center;
}

.sentry-error-sub-text {
    font-size: 12px;
    font-weight: 400;
    font-weight: 400;
    height: 40px;
    color: #270f36;
    width: 260px;
    margin-top: 12px;
    text-align: center;
}

.sentry-error-cta-holder {
    width: inherit;
    position: fixed;
    bottom: 0;
    background-color: #f7f8fc;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 0;
}

.sentry-error-cta,
.sentry-error-cta:hover,
.sentry-error-cta:focus {
    margin-left: 12px;
    margin-right: 12px;
    height: 48px;
    align-self: stretch;
    background-color: #270f36;
    color: white !important;
    box-shadow: 0 3px 6px 0 #b6b6b6;
    border: none !important;
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
